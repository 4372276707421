import * as React from 'react'

import * as styles from './styles.module.scss'

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.contacts}>
        <div className={styles.column}>
          <div className={styles.name}>Контакты</div>
          <div className={styles.text}>
            <a href='mailto:yu_ko@internet.ru' target={'_blank'} className={styles.label}>yu_ko@internet.ru</a>
            <a href='tel:+79093141392' target={'_blank'} className={styles.label}>+7(909) 314-13-92</a>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.name}>Юридический адрес</div>
          <div className={styles.text}>
            <div className={styles.label}>420094, Республика Татарстан, г. Казань, ул. Голубятникова д.20А помещ.2-7</div>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.name}>Почтовый адрес</div>
          <div className={styles.text}>
            <div className={styles.label}>420094, Республика Татарстан, г. Казань, ул. Голубятникова д.20А помещ.2-7</div>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.name}>ОГРН</div>
          <div className={styles.text}>
            <div className={styles.label}>1201600047880 от 14.07.2020</div>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.name}>ИНН</div>
          <div className={styles.text}>
            <div className={styles.label}>1660347593</div>
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer