import * as React from 'react'

import * as styles from './styles.module.scss'

interface IProps {
  icon: React.JSX.Element
  title: string
  text: string
}

const StructureCard = ({ icon, title, text }: IProps) => {
  return (
    <div className={styles.card}>
      {icon}
      <h3>{title}</h3>
      <div className={styles.text}>{text}</div>
    </div>
  )
}
export default StructureCard