import classNames from "classnames"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import * as React from "react"

import * as styles from './styles.module.scss'

interface IProps {
  to: string
  className?: string
  children: any
  onClick?: () => void
}
const Link = ({ children, to, className, onClick }: IProps) => {
  return (
    <AnchorLink to={to} onAnchorLinkClick={onClick} className={classNames(styles.link, className)}>{children}</AnchorLink>
  )
}

export default Link