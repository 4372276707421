import * as React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import classNames from 'classnames';

import * as styles from './styles.module.scss'
import { ApproveIcon, DevelopIcon, InvestIcon, MarketingIcon, MobileIcon, TraficIcon } from '../atoms/Icons';

const slides = [
  {
    id: 1,
    icon: <DevelopIcon />,
    title: "Разработка",
    texts: [
      "Разработка программного обеспечения для международных партнеров в FinTeсh сфере",
      "Сопровождение и обслуживание ПО",
      "Автоматизация процессов",
    ]
  },
  {
    id: 2,
    icon: <TraficIcon />,
    title: "Трафик",
    texts: [
      "Привлечение",
      "Монетизация",
      "Увеличение среднего количества просмотров в социальных медиа",
    ]
  },
  {
    id: 3,
    icon: <MobileIcon />,
    title: "Mobile Apps",
    texts: [
      "Создание",
      "Продвижение",
      "Сопровождение и ведение существующего проекта",
    ]
  },
  {
    id: 4,
    icon: <MarketingIcon />,
    title: "CPA Маркетинг",
    texts: [
      "Создание",
      "Продвижение",
      "Сопровождение и ведение существующего проекта",
    ]
  },
  {
    id: 5,
    icon: <InvestIcon />,
    title: "Инвестирование",
    texts: [
      "Инвестиции",
      "Помощь в привлечении инвестиций",
      "Масштабирование IT-бизнеса",
    ]
  }
]

const NewCarousel = () => {
  return (
    <div className={styles.carousel}>
      <Swiper
        modules={[Autoplay]}
        spaceBetween={30}
        speed={1000}
        autoplay={{
          disableOnInteraction: false,
        }}
        breakpoints={{
          320: {
            slidesPerView: "auto",
            centeredSlides: true
          },
          768: {
            slidesOffsetBefore: 48,
            slidesOffsetAfter: 48,
            slidesPerView: 2
          },
          1280: {
            slidesOffsetBefore: 125,
            slidesOffsetAfter: 125,
            slidesPerView: 3
          },
          1440: {
            slidesOffsetBefore: 125,
            slidesOffsetAfter: 125,
            slidesPerView: 3
          },
          1600: {
            slidesOffsetBefore: 0,
            centeredSlides: true,
            slidesPerView: 4
          }
        }}
      >
        {slides.map(({ id, icon, title, texts }) => (
          <SwiperSlide className={styles.slide} key={id}>
            <div className={styles.item}>
              <div className={styles.header}>{icon}</div>
              <div className={styles.content}>
                <div className={styles.title}>{title}</div>
                <div className={styles.list}>
                  {texts.map(text => <div className={styles.text}><ApproveIcon /> {text}</div>)}
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
export default NewCarousel